@import '../../variables.sass';


label{
    display: block;
    font-size: 23px;
    font-family: $Font-Bold;
    padding-bottom: 30px;
}

input{
    width: 100%;
   border: none;
   text-transform: capitalize;
   border-bottom: 1px solid #707070;
   background-color: transparent;
   font-size: 22px;
   font-family: $Font-Regular;
   color: $white;
   margin-bottom: 36px;
   padding-bottom: 15px;
   &:focus{
    outline: none;
    &::placeholder{

        color: rgba(255,255,255,.8)
    }
   }
    
}
.phone{
    margin-bottom: 34px;
    
}

.checkboxContainer{
    display: block;
    position: relative;
    cursor: pointer;
    user-select: none;
    font-family: $Font-Regular;
    font-size: 20px;
    opacity: .8;
    input{
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    }
     
}
.checkbox{
    display: inline-block;
    height: 20px;
    width: 20px;
    margin-right: 4px;
    background: $white;
    border: 2px $white solid;
}

.checkbox--active{
    border-color: $white;
    background: $black;
}

.btnSubmit{
    button{
        color: $white;
        border: 1px solid rgba(255,255,255,.1);
        width: 188px;
        font-size: 16px;
        font-family: $Font-Regular;
        height: 56px;
        background-color: transparent;
        border-radius: 4px;
        display: block;
        &:hover{
            border: 1px solid rgba(255,255,255,1);
        }
          
        &:focus{
            outline: none;
        a{
            color: $white;
            text-decoration: none;
            line-height: 56px;
        }
       
        }
         
    }
    
}
@media only screen and (max-width: 575.98px){
    label{
        font-size: 16px;
        padding-bottom: 15px;
    }
    input{
        font-size: 14px;
        margin-bottom: 20px;
   padding-bottom: 5px;
    }
    .checkboxContainer{
        font-size: 14px;
    }
    .checkbox{
        height: 14px;
        width: 14px;
       
    }
}

    