@import '../../../variables';
.newsInnerContainer {
 padding: 100px 0;
 color: $dark-grey;
 .backIconForNews {
  transform: rotate(180deg);
  font-size: 70px;
  cursor: pointer; }
 h2 {
  font-size: 60px;
  font-family: $Font-Black;
  padding-bottom: 20px;
  color: $dark-grey;
  text-transform: uppercase; }
 span {
  font-size: 18px;
  font-family: Montserrat-Medium;
  opacity: .4; }
 .typeAndDateContainer {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  font-size: 18px;
  font-family: Montserrat-Medium;
  h3 {
   font-size: 18px;
   font-family: Montserrat-Medium;
   color: $dark-grey; } }
 svg {
  color: $dark-grey;
  margin: 0 10px; }
 p {
  font-size: 18px;
  font-family: $Font-Medium;
  padding-top: 40px;
  strong {
    font-family: $Font-Bold; } }
 h3 {
  font-size: 40px;
  font-family: $Font-Medium;
  padding-top: 70px;
  padding-bottom: 40px; }
 svg {
   color: $dark-grey;
   margin: 0 10px; } }

@media only screen and (max-width: 575.98px) {
 .newsInnerContainer {
  .backIconForNews {
   font-size: 50px; }
  h2 {
   font-size: 40px; }
  span {
   font-size: 16px; }
  .typeAndDateContainer {
   font-size: 16px;
   h3 {
    font-size: 16px; } }
  p {
   font-size: 16px; }
  h3 {
   font-size: 30px;
   padding-top: 40px;
   padding-bottom: 20px; } } }



