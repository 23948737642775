@import './variables.sass';
@font-face {
  font-family: 'Montserrat-Medium';
  src: url('./fonts/Montserrat-Medium.eot');
  src: local('Montserrat Medium'), local('Montserrat-Medium'), url('./fonts/Montserrat-Medium.eot?#iefix') format('embedded-opentype'), url('./fonts/Montserrat-Medium.woff2') format('woff2'), url('./fonts/Montserrat-Medium.woff') format('woff'), url('./fonts/Montserrat-Medium.ttf') format('truetype'), url('./fonts/Montserrat-Medium.svg#Montserrat-Medium') format('svg');
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat-Light';
  src: url('./fonts/Montserrat-Light.eot');
  src: local('Montserrat Light'), local('Montserrat-Light'), url('./fonts/Montserrat-Light.eot?#iefix') format('embedded-opentype'), url('./fonts/Montserrat-Light.woff2') format('woff2'), url('./fonts/Montserrat-Light.woff') format('woff'), url('./fonts/Montserrat-Light.ttf') format('truetype'), url('./fonts/Montserrat-Light.svg#Montserrat-Light') format('svg');
  font-weight: 300;
  font-style: normal; }


@font-face {
  font-family: 'Montserrat-Regular';
  src: url('./fonts/Montserrat-Regular.eot');
  src: local('Montserrat Regular'), local('Montserrat-Regular'), url('./fonts/Montserrat-Regular.eot?#iefix') format('embedded-opentype'), url('./fonts/Montserrat-Regular.woff2') format('woff2'), url('./fonts/Montserrat-Regular.woff') format('woff'), url('./fonts/Montserrat-Regular.ttf') format('truetype'), url('./fonts/Montserrat-Regular.svg#Montserrat-Regular') format('svg');
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat-Black';
  src: url('./fonts/Montserrat-Black.eot');
  src: local('Montserrat Black'), local('Montserrat-Black'), url('./fonts/Montserrat-Black.eot?#iefix') format('embedded-opentype'), url('./fonts/Montserrat-Black.woff2') format('woff2'), url('./fonts/Montserrat-Black.woff') format('woff'), url('./fonts/Montserrat-Black.ttf') format('truetype'), url('./fonts/Montserrat-Black.svg#Montserrat-Black') format('svg');
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat-Bold';
  src: url('./fonts/Montserrat-Bold.eot');
  src: local('Montserrat Bold'), local('Montserrat-Bold'), url('./fonts/Montserrat-Bold.eot?#iefix') format('embedded-opentype'), url('./fonts/Montserrat-Bold.woff2') format('woff2'), url('./fonts/Montserrat-Bold.woff') format('woff'), url('./fonts/Montserrat-Bold.ttf') format('truetype'), url('./fonts/Montserrat-Bold.svg#Montserrat-Bold') format('svg');
  font-weight: bold;
  font-style: normal; }


html {
  &>canvas {
    display: none; } }

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0; }

img {
  width: 100%;
  display: block; }

.sticky-copyright {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  height: 100vh;
  align-items: center;
  padding: 25px;
  position: fixed;
  pointer-events: none;
  z-index: 1001;
  p {
    writing-mode: vertical-lr;
    transform: rotateZ(180deg);
    font-family: $Font-Medium;
    color: $black;
    a {
      font-family: $Font-Bold;
      font-size: 16px;
      color: inherit;
      pointer-events: all; } } }
.PhoneInput {
 display: flex;
 align-items: center;
 margin-bottom: 50px;
 .PhoneInputInput {
  flex: 1;
  min-width: 0;
  width: 100%;
  border: none;
  text-transform: capitalize;
  border-bottom: 1px solid #707070;
  background-color: transparent;
  font-size: 22px;
  font-family: $Font-Regular;
  color: rgba(255,255,255,1);
  padding-bottom: 10px;
  &:focus {
   outline: none; }
  &::placeholder {
   color: rgba(255,255,255,.8); } }
 .PhoneInputCountryIcon {
  width: 40px; }
 .PhoneInputCountryIcon--square {
  width: 40px; }
 .PhoneInputCountryIcon--border {
  background-color: rgba(0,0,0,0.1);
  box-shadow: 0 0 0 1px rgba(0,0,0,0.5); }
 .PhoneInputCountryIconImg {
  display: block;
  width: 100%; }
 .PhoneInputCountry {
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  margin-right: 20px; }
 .PhoneInputCountrySelect {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  border: 0;
  opacity: 0;
  cursor: pointer; }
 .PhoneInputCountrySelect[disabled] {
  cursor: default; }
 .PhoneInputCountrySelect[readonly] {
  cursor: default; }
 .PhoneInputCountrySelectArrow {
  display: block;
  width: 15px;
  height: 15px;
  margin-left: 20px;
  border-style: solid;
  border-color: $white;
  opacity: .8;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg); } }
#portal {
 .container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  .modal {
   z-index: 1;
   width: 560px;
   height: 315px;
   background: white;
   display: flex;
   align-items: center;
   justify-content: center;
   border-radius: 5px;
   position: relative;
   animation: animate 0.3s;
   button {
    position: absolute;
    top: 7px;
    right: 7px;
    padding: 8px 11px;
    background: black;
    color: white;
    font-weight: bold;
    border: none;
    outline: none;
    border-radius: 2px;
    cursor: pointer; } } } }
@media only screen and (max-width: 991px) {
  main {
    //margin-top: 100px
    .sticky-copyright {
      display: none; } } }

