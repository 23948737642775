@import '../../variables.sass';
.conatiner{
    background: url("../../imgs/social-calender-bg.jpg");
    position: relative;
    min-height: 100vh;
    margin-top: 80px;
    &:after{
position: absolute;
top: 50%;
padding-top: 80px;
transform: translateY(-50%);
content: url("../../imgs/date.png");
    }
    .yellow{
        padding: 112px 0;
        h2{
            font-size: 60px;
            font-family: $Font-Bold;
            text-transform: uppercase;
            text-shadow: 0 3px 6px rgba(0,0,0,.16);
padding: 58px 54px;
        }
        div{
            background: $linear-yellow;
border-radius: 20px 0 0 20px;
        }
    }
    .black{
        padding: 52px 0;
        .blackContainer{
            background: $black;
            border-radius: 20px;
            color: $white;
            padding: 52px 56px 40px;
            position: relative;
            ul{
                position: absolute;
                top:50%;
                transform: translateY(-50%);
                right: 8%;
                list-style: none;
                &::after{
                    content: "";
                    background-color:$dark-grey2 ;
                    width: 3px;
                    height: 216px;
                    display: block;
                    margin: 0 auto;
                }
                &::before{
                    content: "";
                    background-color:$dark-grey2 ;
                    width: 3px;
                    height: 216px;
                    display: block;
                    margin: 0 auto;
                }
                li{
                    margin: 20px 0px;
                    text-align: center;
                }
                
                a{
                    color: $dark-grey2;
                    font-size: 27px;
                }
            }
            h3{
                font-size: 35px;
                font-family: $Font-Bold;
                text-transform: uppercase;
                margin-bottom: 44px;
                span{
                    text-transform: none;
                    font-size: 20px;
                    font-family: $Font-Regular;
                    opacity: .5;
                    display: block;
                    margin-top: 25px;
                }
            }
        }
    }
}
@media only screen and (max-width: 575.98px)
{.conatiner{
    .yellow{
        h2{
            font-size: 30px;
        }
        div{
            border-radius: 20px ;
        }
    }
    .black{
        padding: 0px;
        ul{
            a{
                font-size: 22px !important;
            }
            li{
                margin: 10px 0px !important;
            }
        }
        h3{
            font-size:30px;
            
        }
    }
}

}