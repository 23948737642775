@import '../../../variables.sass';
.sectionContainer {
	min-height: 100vh;
	overflow-y: hidden;
	position: relative;
	background-color: $black;
	color: $white;
	.scrollDown {
		position: absolute;
		color: $white;
		writing-mode: tb;
		left: 20px;
		top: 50%;
		transform: translateY(-50%);
		font-family: $Font-Medium;
		font-size: 18px;
		display: flex;
		p {
			transform: rotateZ(-180deg); }
		img {
			display: inline-block;
			width: 7px;
			height: 27px;
			transform: translate(-7px, 7px); } }
	.section {
		font-family: $Font-Black;
		height: 100vh;
		&>div {
			height: 100%;
			h2 {
				font-size: 80px;
				line-height: 1.21;
				position: relative;
				margin-bottom: 30px;
				color: $white;
				&:after {
					content: attr(data-text);
					font-size: 100px;
					line-height: 1.22;
					position: absolute;
					left: 0;
					top: 0;
					-webkit-text-fill-color: transparent;
					-webkit-text-stroke: 1px rgba($white, 0.3);
					transform: translate(-3% ,-10%); } }
			button {
				background-color: transparent;
				font-family: $Font-Regular;
				cursor: pointer;
				outline: none;
				border: none;
				color: $white;
				transition: all 0.5s;
				display: block;
				border: 2px solid rgba($white, 0.1);
				padding: 20px 40px;
				border-radius: 5px;
				text-decoration: none;
				&:hover {
				 border-color: rgba($white, 1); }
				.popup {
				 position: absolute;
				 width: 100%;
				 height: 100%;
				 top: 50%;
				 left: 0;
				 z-index: 100;
				 .backdrop {
				  width: 100%;
				  position: fixed;
				  background-color: rgba(0,0,0,0.4);
				  height: 100%;
				  top: 0;
				  left: 0;
				  z-index: -1;
				  .close {
				   position: absolute;
				   top: 20%;
				   right: 20%;
				   font-size: 30px; } }
				 iframe {
				  transform: translate(-50%,-50%);
				  position: fixed;
				  top: 50%;
				  left: 50%; } } }
			.firstSectionImgContainer {
				align-self: stretch;
				* {
					height: 100%; }
				.firstSectionImg {
					height: 100%;
					background: $linear-yellow;
					background-color: $yellow;
					position: relative;
					display: flex;
					align-items: flex-end;
					img {
						height: unset;
						transition: all 0.5s;
						&:first-child, &:last-child {
							position: absolute;
							bottom: 0;
							left: 50%;
							transform: translate(-50%, 10%) scale(0.7);
							z-index: 1; }
						&:nth-child(2) {
							position: relative;
							z-index: 2; } }
					&:hover {
							img {
								&:first-child, &:last-child {
										transform: translate(-50%, 10%) scale(0.95); }
								&:first-child {
										left: 30%; }
								&:last-child {
										left: 70%; } } } } } } } }
@media only screen and (max-width: 991px) {
	.sectionContainer {
		&>div:first-child {
				display: none; }
		.section {
				height: auto;
				padding: 0;
				padding-top: 100px;
				&>div {
						&>div {
								padding: 0 16px; }
						h2 {
								font-size: 65px;
								line-height: 86px;
								&:after {
										font-size: 75px;
										line-height: 102px;
										left: 20px;
										top: 0; } }
						button {
								margin-bottom: 50px; }
						.firstSectionImg {
								overflow-x: hidden; } } } } }
@media only screen and (max-width: 575.98px) {
 .sectionContainer {}
	&>div:first-child {
		writing-mode: tb;
		font-size: 18px;
		img {
			width: 7px;
			height: 27px; } }
	.section {
		&>div {
			h2 {
				font-size: 36px !important;
				white-space: break-spaces;
				line-height: 44px !important;
				margin-bottom: 10px !important;
				&:after {
					font-size: 41px !important;
					line-height: 50px !important; } }
			button {
                padding: 17px 30px !important; } } }
	.scrollDown {
	    display: none !important; } }
